export const componentToHex = (c) => {

  const hex = parseInt(c, 10).toString(16).toUpperCase();
  return hex.length === 1 ? `0${hex}` : hex;

};

export const rgbToHex = (r, g, b) => (

  `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`

);

export const hexToRgb = (hex, a) => {

  // Expand shorthand form
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const expanded = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(expanded);
  return (result && a !== undefined)
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a,
    }
    : {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };

};

export const formColor = (recStyles, active, a1, a2) => {

  const style = {};

  if (
    recStyles !== undefined
    && !active
    && a1 === undefined
    && a2 === undefined
  ) {

    const bgColor = (
      recStyles.solid !== ''
        ? recStyles.solid
        : recStyles.gradient.from
    );
    const to = (
      recStyles.solid !== ''
        ? undefined
        : recStyles.gradient.to
    );

    const bgLinear = to ? `linear-gradient(to right, ${bgColor} , ${to})` : undefined;

    if (recStyles.solid !== '') {

      style.backgroundColor = bgColor;

    } else {

      style.background = bgLinear;

    }

  } else if (
    recStyles !== undefined
    && (active || a1 !== undefined || a2 !== undefined)
  ) {

    let rgba;
    let rgbaTo;

    if (recStyles.solid !== '' && (recStyles.solid.length === 7 || recStyles.solid.length === 4)) {

      const color = recStyles.solid;
      const rgbaRec = hexToRgb(color, a1);

      if (a1 !== undefined) {

        rgba = `rgba(${rgbaRec.r},${rgbaRec.g},${rgbaRec.b},${rgbaRec.a})`;

      } else {

        rgba = `rgb(${rgbaRec.r},${rgbaRec.g},${rgbaRec.b})`;

      }


    } else if (
      recStyles.gradient.from
      && (recStyles.gradient.from.length === 7 || recStyles.gradient.from.length === 4)
    ) {

      const colorFrom = recStyles.gradient.from;
      const colorTo = recStyles.gradient.to;
      const rgbaRec = hexToRgb(colorFrom, a1);
      const rgbaToRec = hexToRgb(colorTo, a2);

      if (a1 !== undefined && a2 === undefined) {

        rgba = `rgba(${rgbaRec.r},${rgbaRec.g},${rgbaRec.b},${rgbaRec.a})`;
        rgbaTo = `rgba(${rgbaToRec.r},${rgbaToRec.g},${rgbaToRec.b})`;

      } else if (a1 === undefined && a2 !== undefined) {

        rgba = `rgba(${rgbaRec.r},${rgbaRec.g},${rgbaRec.b})`;
        rgbaTo = `rgba(${rgbaToRec.r},${rgbaToRec.g},${rgbaToRec.b},${rgbaToRec.a})`;

      } else if (a1 !== undefined && a2 !== undefined) {

        rgba = `rgba(${rgbaRec.r},${rgbaRec.g},${rgbaRec.b},${rgbaRec.a})`;
        rgbaTo = `rgba(${rgbaToRec.r},${rgbaToRec.g},${rgbaToRec.b},${rgbaToRec.a})`;

      } else {

        rgba = `rgb(${rgbaRec.r},${rgbaRec.g},${rgbaRec.b})`;
        rgbaTo = `rgb(${rgbaToRec.r},${rgbaToRec.g},${rgbaToRec.b}})`;

      }

    }

    const bgColor = rgba;
    const to = rgbaTo;

    const bgLinear = to ? `linear-gradient(to right, ${bgColor} , ${to})` : undefined;

    if (recStyles.solid !== '') {

      style.backgroundColor = bgColor;

    } else {

      style.background = bgLinear;

    }

  }

  return style;

};

export const constructLink = (l, pageIndex, articleIndex) => {

  let link = l;
  let type = 'EXTERNAL';

  if (typeof link === 'string') {

    if (link && link.startsWith('/')) type = 'PAGE';
    else if (link && link.startsWith('#')) type = 'SECTION';

  } else if (link) {

    ({ type } = link);
    if ((link.type === 'PAGE' || link.type === 'SECTION') && link.data && pageIndex && link.data.pageId in pageIndex) {

      const page = pageIndex[link.data.pageId];
      if (l.type === 'SECTION') link = `${page}#${l.data.sectionId}`;
      else link = page;

    } else if (link.type === 'EXTERNAL' && link.data) {

      ({ link } = link.data);

    } else if (link.type === 'ARTICLE' && link.data && articleIndex && link.data.articleId in articleIndex) {

      link = articleIndex[link.data.articleId];

    } else link = '';

  }

  return { link, type };

};

export const createPreviewPath = (image) => {

  let start = image.path;
  const sIndex = image.path.lastIndexOf('.');
  let ext = '';
  if ((sIndex > 0) && (sIndex > image.path.lastIndexOf('/'))) {

    start = image.path.substring(0, sIndex);
    ext = image.path.substring(sIndex);

  }

  const size = image.sizes.includes('la') ? '-la' : `-${image.sizes[image.sizes.length - 1]}`;
  const src = encodeURI(`${process.env.IMAGES_CDN}/${start}${size}${ext}`)
    .replace('+', '%2B');

  return src;

};
